@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";



body {
  font-family: 'Montserrat', sans-serif;
  background-color: rgb(20, 20, 20);
  color: white;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

}


.showOnLargeDeviceAndHideOnSmall {
  display: block;
}

@media (max-width: 768px) {
  .showOnLargeDeviceAndHideOnSmall {
    display: none;
  }
}

/* If you use a global CSS file */
.slick-slide {
  margin: 0 15px; /* Add horizontal space between slides */
}

/* Ensures the focus state is also visually appealing */
.slick-slide:focus {
  outline: none;
}

/* Adjustments for smaller screens can be made as well */
@media (max-width: 600px) {
  .slick-slide {
    margin: 0 10px; /* Smaller margins for smaller screens */
  }
}



